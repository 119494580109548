div.main-content-section {
    margin-left: calc(300px + 1rem);
    width: calc(100% - 300px - 1rem);
    display: block;
    height: 100%;
    padding-top: 1rem;
}

h1.main-content-heading {
    font-size: min(8rem, 6vw);
    padding: 1rem;
}

h4.main-content-description {
    font-size: 1.5rem;
    color: #444;
    /* margin-top: 1rem; */
    padding: 1rem;
}

@media only screen and (max-width: 400px) {
    div.main-content-section {
        width: 100%;
        height: calc(100vh - 1.6rem);
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    h1.main-content-heading {
        font-size: 3rem;
    }
}